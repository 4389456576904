import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
@Injectable()
export class Globals {
  //   WEBAPI = 'http://localhost/foh_v3/api/public/api/';
  //    UPLOADS = 'http://localhost/foh_v3/api/public/storage/';
  //    URL_SOCKET = "http://localhost:3030";

  //WEBAPI = 'https://demo.foh.solutions/api/';
  //UPLOADS = 'https://demo.foh.solutions/storage/';
  //URL_SOCKET = "https://foh3vm1.westeurope.cloudapp.azure.com:3030";

  //WEBAPI = 'https://v2.foh.solutions/api/';
  //    UPLOADS = 'https://v2.foh.solutions/storage/';
  //    URL_SOCKET = "https://foh3vm1.westeurope.cloudapp.azure.com:3030";

  // WEBAPI = 'https://1658-156-204-239-230.ngrok-free.app/api/';

  WEBAPI = null;
  UPLOADS = null;
  URL_SOCKET = null;
  STORAGE_PREFIX = null;
  SECURECOOKIE = null;
  DOMAIN = null;

  setConstants(vars) {
    this.WEBAPI = vars.WEBAPI;
    this.UPLOADS = vars.UPLOADS;
    this.URL_SOCKET = vars.URL_SOCKET;
    this.STORAGE_PREFIX = vars.STORAGE_PREFIX;
    this.SECURECOOKIE = vars.SECURECOOKIE;
    this.DOMAIN = vars.DOMAIN;
  }

  STORAGE_USER = "user";
  STORAGE_TOKEN = "token";
  STORAGE_CUSTOMERTOKEN = "custoken";
  STORAGE_CUSTOMER = "customer";
  KEYWORD_CITADEL = environment.KEYWORD_CITADEL;

  GOOGLEANALYTICS = environment.GOOGLEANALYTICS;

  CURRENT_VERSION = "3.6.1";

  CITADEL_FRONTEND_URL = "https://salahaldincitadel.com/admin/";

  /*Auth */
  URL_SIGNIN = "auth/login";

  URL_LOOKUPS_RETRIEVE = "systemsetup/lookups/get";
  URL_DASHBOARD_RETRIEVE = "dashboard/get";
  URL_DASHBOARD_UPDATEWEEK = "dashboard/updateweek";
  URL_NOTIFICATIONS_MARKASREAD = "systemsetup/notifications/markasread";

  /*Superadmin*/
  URL_SUPERADMIN_LIST = "usersmangement/superadmin/all";
  URL_SUPERADMIN_GET = "usersmangement/superadmin/get";
  URL_SUPERADMIN_CREATE = "usersmangement/superadmin/create";
  URL_SUPERADMIN_UPDATE = "usersmangement/superadmin/update";
  URL_SUPERADMIN_DELETE = "usersmangement/superadmin/delete";

  URL_MCOMPANY_LIST = 'systemsetup/mcompanies';
  URL_MCOMPANY_GET = 'systemsetup/mcompanies';
  URL_MCOMPANY_CREATE = 'systemsetup/mcompanies';
  URL_MCOMPANY_UPDATE = 'systemsetup/mcompanies';
  URL_MCOMPANY_DELETE_LOGO = 'systemsetup/mcompanies';

  // Venues Endpoints
  URL_VENUE_CREATE = 'systemsetup/venues';
  URL_VENUE_UPDATE = 'systemsetup/venues';
  URL_VENUE_DELETE_LOGO = 'systemsetup/venues';
  URL_VENUE_LIST = 'systemsetup/venues';
  URL_VENUE_GET = 'systemsetup/venues';

  // Branches Endpoints
  URL_BRANCH_CREATE = 'systemsetup/branches';
  URL_BRANCH_UPDATE = 'systemsetup/branches';
  URL_BRANCH_DELETE_LOGO = 'systemsetup/branches';
  URL_BRANCH_LIST = 'systemsetup/branches';
  URL_BRANCH_GET = 'systemsetup/branches';

  // SMS Recharge
  URL_SMSRECHARGE = 'messages/sms/allocate';
  URL_SMSTRANSACTIONS = 'messages/sms/transactions/allocated';

  /*Users*/
  URL_USERS_LIST = "usersmangement/users/list";
  URL_USERS_LOGS_LIST = "usersmangement/users/logs/list";
  URL_USERS_LOGS_EXPORT = "usersmangement/users/logs/export";
  URL_USERS_GET = "usersmangement/users/get";
  URL_USERS_CREATE = "usersmangement/users/create";
  URL_USERS_UPDATE = "usersmangement/users/update";
  URL_USERS_DELETE = "usersmangement/users/delete";

  /*Roles*/
  URL_ROLES_LIST = "usersmangement/roles/list";
  URL_ROLES_GET = "usersmangement/roles/get";
  URL_ROLES_CREATE = "usersmangement/roles/create";
  URL_ROLES_UPDATE = "usersmangement/roles/update";
  URL_ROLES_DELETE = "usersmangement/roles/delete";

  /*Permission*/
  URL_PERMISSIONS_LIST = "usersmangement/permissions/list";

  /*System Setup*/
  URL_CURRENCIES_LIST = "systemsetup/currencies/all";
  URL_CURRENCIES_GET = "systemsetup/currencies/get";
  URL_CURRENCIES_CREATE = "systemsetup/currencies/create";
  URL_CURRENCIES_UPDATE = "systemsetup/currencies/update";
  URL_CURRENCIES_DELETE = "systemsetup/currencies/delete";

  /*Reservation Retrievale*/
  URL_RESERVATIONS_STARTSTREAMWLAYOUT = "reservations/fullstreamlayout/start";
  URL_RESERVATIONS_CONTINUESTREAMWLAYOUT =
    "reservations/fullstreamlayout/continue";
  URL_RESERVATIONS_FULLRETRIEVEWLAYOUT = "reservations/fullretrievewlayout";
  URL_RESERVATIONS_FULLRETRIEVE = "reservations/fullretrieve";
  URL_RESERVATIONSANDREQUESTS_LIST = "reservations/reservationsandrequestslist";

  /*Reservation*/
  URL_RESERVATIONS_LIST = "reservations/list";
  URL_RESERVATIONS_CREATE = "reservations/create";
  URL_WALKIN_CREATE = "walkin/create";
  URL_RESERVATIONS_EDIT = "reservations/edit";
  URL_RESERVATIONS_CONFIRM = "reservations/confirm";
  URL_RESERVATIONS_MASSCONFIRM = "reservations/massconfirm";
  URL_RESERVATIONS_CLOSE = "reservations/close";
  URL_RESERVATIONS_GENERATERECEIPTPDF = "reservations/generatereceiptpdf";
  URL_RESERVATIONS_SENDNOTIFICATION = "reservations/sendnotificaion";
  URL_RESERVATIONS_CANCEL = "reservations/cancel";
  URL_RESERVATIONS_STATS = "reservations/statsbar/get";
  URL_RESERVATIONS_ALLSLOTSSTATS = "reservations/statsbar/allslots";
  URL_RESERVATIONS_EXPORT = "reservations/export";
  URL_RESERVATIONS_SEARCH = "reservations/search";
  URL_RESERVATIONS_RETRIEVE = "reservations/get";
  URL_RESERVEDTABLES_LIST = "reservedtables/list";

  /*Reservations Legacy*/
  URL_RESERVATIONS_FULLRETRIEVEW_LEGACY = "reservations/legacy/fullretrieve";
  URL_RESERVATIONS_RETRIEVELAYOUT_LEGACY = "reservations/legacy/retrievelayout";

  /*FOH Tables*/
  URL_FOHTABLES_ALLOCATE = "fohtables/allocate";
  URL_FOHTABLES_DEALLOCATE = "fohtables/deallocate";
  URL_FOHTABLES_DEALLOCATEALL = "fohtables/deallocateall";

  URL_GUESTBOOK_LIST = "guestbook/list";
  URL_GUESTBOOK_UPDATE = "guestbook/update";
  URL_GUESTBOOK_SEARCHCODE = "guestbook/searchcode";
  URL_GUESTBOOK_STATS = "guestbook/stats";
  URL_GUESTBOOK_DOWNLOAD = "guestbook/list/download";

  URL_RESERVATIONS_DEPOSITLIST_RETRIEVE = "reservations/depositlist/get";
  URL_RESERVATIONS_DEPOSITLIST_UPDATETOTALREQUIRED =
    "reservations/depositlist/updatetotalrequired";
  URL_RESERVATIONS_DEPOSITLIST_ADDRECORD = "reservations/depositlist/addrecord";
  URL_RESERVATIONS_DEPOSIT_PAYMENTLINK =
    "reservations/depositlist/sendpaymentlink";
  URL_PAYMENTS_DEPOSIT_RESERVATIONINFO =
    "payments/deposit/retrievereservationinfo";
  URL_PAYMENTS_DEPOSIT_CREATEPAYMENTLINK = "payments/deposit/createpaymentlink";
  URL_PAYMENTS_RETRIEVEDEPOSITORDERSTATUS =
    "payments/deposit/retrieveorderstatus";

  URL_RESERVATIONS_ACTIVITYLOGS_RETRIEVE = "reservations/activitylog/list";
  URL_RESERVATIONS_MESSAGESLOGS_RETRIEVE = "reservations/messageslog/list";
  URL_RESERVATIONS_QRCODESLIST = "reservations/qrcodes/list";

  /*QR Invitations*/
  URL_RESERVATIONS_QRINVITATIONS_METADATA = "reservations/qrinvitations/metadata";
  URL_RESERVATIONS_QRINVITATIONS_CREATE = "reservations/qrinvitations/create";
  URL_RESERVATIONS_MASSQRINVITATION = "reservations/qrinvitations/masscreate";
  URL_RESERVATIONS_QRINVITATIONS_RETRIEVE = "reservations/qrinvitations/retrieve";

  /*Customers*/
  URL_CUSTOMERS_LIST = "customers/list";
  URL_CUSTOMERS_BIRTHDAY_LIST = "customers/birthday/list";
  URL_CUSTOMERS_GET = "customers/get";
  URL_CUSTOMERS_REFRESHSTATS = "customers/refreshstats";
  URL_CUSTOMERS_SEARCH = "customers/search";
  URL_CUSTOMERS_CREATE = "customers/create";
  URL_CUSTOMERS_CREATE_W_REPLACE_GUEST = "customers/create/wreplaceguest";
  URL_CUSTOMERS_UPDATE = "customers/update";
  URL_CUSTOMERS_DELETE = "customers/delete";
  URL_CUSTOMERS_VISITS = "customers/vists";
  URL_BLACKLIST_LIST = "customers/blacklisted"

  URL_CUSTOMERS_CHANGEMOBILENUMBER = "customers/changemobilenumber";
  URL_CUSTOMERS_ACTIVITYLOGS = "customers/activitylogs";
  URL_CUSTOMERS_GETTOTALNUMBEROFCUSTOMERS =
    "customers/checktotalnumberofcustomers";
  URL_CUSTOMERS_GETNUMBEROFCUSTOMERSBYCLIENTID =
    "customers/checknumberofcustomersperclientcat";
  URL_CUSTOMERPROFILE_SIGNIN = "customerprofile/login";
  URL_CUSTOMERPROFILE_SIGNUP = "customerprofile/register";
  URL_CUSTOMERPROFILE_PROFILE = "customerprofile/retrieve";
  URL_CUSTOMERPROFILE_UPDATE = "customerprofile/update";

  /*Guestlist*/
  URL_GUESTLIST_LIST = "reservations/guestlist/list";
  URL_GUESTLIST_STREAM_START = "reservations/guestlist/stream/start";
  URL_GUESTLIST_ALLRESERVATIONS = "reservations/guestlist/reservationlist";
  URL_GUESTLIST_CREATE = "reservations/guestlist/create";
  URL_GUESTLIST_REMOVE = "reservations/guestlist/remove";
  URL_GUESTLIST_SWITCHOVERRIDEOFF = "reservations/guestlist/switchoff";
  URL_GUESTLISTS_SEARCH = "reservations/guestlist/search";
  URL_GUESTLISTS_UPDATESTATUS = "reservations/guestlist/updatestatus";
  URL_GUESTLISTS_CONVERTTOGENERICGUESTLIST = "reservations/guestlist/converttogeneric";



  /*Ticket List*/
  URL_TICKETLIST_LIST = "reservations/ticketlist/list";
  URL_TICKETLIST_STREAM_START = "reservations/ticketlist/stream/start";
  URL_TICKETLIST_ALLRESERVATIONS = "reservations/ticketlist/reservationlist";
  URL_TICKETLIST_CREATE = "reservations/ticketlist/create";
  URL_TICKETLIST_REMOVE = "reservations/ticketlist/remove";
  URL_TICKETS_SEARCH = "reservations/ticketlist/search";
  URL_TICKETLIST_PAIDSTATUS = "reservations/ticketlist/statusupdate";
  URL_TICKETLIST_RESENDCODE = "reservations/ticketlist/sendcode";
  URL_TICKETLIST_UPDATETICKETCAT = "reservations/ticketlist/updateticketcat";
  URL_TICKETLIST_SENDPAYMENTURL =
    "reservations/ticketlist/sendonlinepaymenturl";
  URL_TICKETLIST_SENDPAYMENTURLTOHOST =
    "reservations/ticketlist/sendonlinepaymenturl/tohost";
  URL_TICKETLIST_UPDATESTATUS = "reservations/ticketlist/updatestatus";
  URL_PAYMENTS_UNPAIDTICKETSLIST = "payments/tickets/retrieveunpaidtickets";
  URL_PAYMENTS_SINGLEPAIDTICKET = "payments/tickets/detailpaid";
  URL_PAYMENTS_TICKETS_GETGROUP = "payments/tickets/getticketsgroup";
  URL_PAYMENTS_TICKETS_CREATEGROUP = "payments/tickets/createticketsgroup";
  URL_PAYMENTS_TICKETS_DELETEGROUP = "payments/tickets/deleteticketsgroup";
  URL_PAYMENTS_TICKETS_CREATEPAYMENTLINK = "payments/tickets/createpaymentlink";
  URL_PAYMENTS_RETRIEVETICKETSORDERSTATUS =
    "payments/tickets/retrieveorderstatus";

  URL_TICKETLIST_VALIDATE_CODE = "reservations/list/validateqr";
  URL_TICKETLIST_CHECKIN_CODE = "reservations/list/checkinqr";

  /*Layouts*/
  URL_LAYOUTS_LIST = "tablesetup/layouts/list";
  URL_LAYOUTS_GET = "tablesetup/layouts/get";
  URL_LAYOUTS_DETECT = "tablesetup/layouts/detect";
  URL_LAYOUTS_CREATE = "tablesetup/layouts/create";
  URL_LAYOUTS_UPDATE = "tablesetup/layouts/update";
  URL_LAYOUTS_DELETE = "tablesetup/layouts/delete";

  /*Floorplans*/
  URL_FLOORPLAN_GET = "tablesetup/floorplans/get";
  URL_FLOORPLAN_SAVE = "tablesetup/floorplans/save";
  URL_FLOORPLAN_UPLOADBACKGROUND = "tablesetup/floorplans/uploadbackground";
  URL_FLOORPLAN_DELETETABLE = "tablesetup/floorplans/deletetable";

  /*Table Types*/
  URL_TABLETYPE_LIST = "mcompanysetup/tabletypes/list";
  URL_TABLETYPE_GET = "mcompanysetup/tabletypes/get";
  URL_TABLETYPE_CREATE = "mcompanysetup/tabletypes/create";
  URL_TABLETYPE_UPDATE = "mcompanysetup/tabletypes/update";
  URL_TABLETYPE_DELETE = "mcompanysetup/tabletypes/delete";

  /*Client Cats*/
  URL_CLIENTCATS_LIST = "mcompanysetup/clientcats/list";
  URL_CLIENTCATS_GET = "mcompanysetup/clientcats/get";
  URL_CLIENTCATS_CREATE = "mcompanysetup/clientcats/create";
  URL_CLIENTCATS_UPDATE = "mcompanysetup/clientcats/update";
  URL_CLIENTCATS_DELETE = "mcompanysetup/clientcats/delete";

  /*Birthday Reminders*/
  URL_BD_LIST = "mcompanysetup/birthdayreminders";
  URL_BD_GET = "mcompanysetup/birthdayreminders";
  URL_BD_CREATE = "mcompanysetup/birthdayreminders/create";
  URL_BD_UPDATE = "mcompanysetup/birthdayreminders/update";
  URL_BD_DELETE = "mcompanysetup/birthdayreminders";


  /*Tags Category*/
  URL_TAGCATS_LIST = "mcompanysetup/tagcats/list";
  URL_TAGCATS_CREATE = "mcompanysetup/tagcats/create";
  URL_TAGCATS_UPDATE = "mcompanysetup/tagcats/update";
  URL_TAGCATS_DELETE = "mcompanysetup/tagcats/delete";

  /*Tags*/
  URL_TAGS_LIST = "mcompanysetup/tags/list";
  URL_TAGS_LISTPERTYPE = "mcompanysetup/tags/listpertype";
  URL_TAGS_LISTPERTAG = "mcompanysetup/tags/listpertag";
  URL_TAGS_ASSIGN = "mcompanysetup/tags/assign";
  URL_TAGS_GET = "mcompanysetup/tags/get";
  URL_TAGS_CREATE = "mcompanysetup/tags/create";
  URL_TAGS_UPDATE = "mcompanysetup/tags/update";
  URL_TAGS_DELETE = "mcompanysetup/tags/delete";

  /*Terms*/
  URL_TERMS_LIST = "mcompanysetup/terms/list";
  URL_TERMS_WEDDING_LIST = "mcompanysetup/terms/tags";
  URL_TERMS_GET = "mcompanysetup/terms/get";
  URL_TERMS_GETBYCODE = "mcompanysetup/terms/getbycode";
  URL_TERMS_CREATE = "mcompanysetup/terms/create";
  URL_TERMS_UPDATE = "mcompanysetup/terms/update";
  URL_TERMS_DELETE = "mcompanysetup/terms/delete";
  URL_TERMS_SEARCHCLIENTCATS = "mcompanysetup/terms/searchcats";

  /*Slots*/
  URL_SLOTS_LIST = "mcompanysetup/slots/list";
  URL_SLOTS_GET = "mcompanysetup/slots/get";
  URL_SLOTS_CREATE = "mcompanysetup/slots/create";
  URL_SLOTS_UPDATE = "mcompanysetup/slots/update";
  URL_SLOTS_DELETE = "mcompanysetup/slots/delete";
  URL_SLOTS_GETLAYOUTSOFBRANCH = "tablesetup/layouts/branches/list";

  /*Open Hours*/
  URL_OPENHOURS_LIST = "mcompanysetup/openhours/list";
  URL_OPENHOURS_GET = "mcompanysetup/openhours/get";
  URL_OPENHOURS_CREATE = "mcompanysetup/openhours/create";
  URL_OPENHOURS_UPDATE = "mcompanysetup/openhours/update";
  URL_OPENHOURS_DELETE = "mcompanysetup/openhours/delete";

  /*Messages Templates*/
  URL_TEMPLATES_LIST = "mcompanysetup/templates/list";
  URL_TEMPLATES_GET = "mcompanysetup/templates/get";
  URL_TEMPLATES_CREATE = "mcompanysetup/templates/create";
  URL_TEMPLATES_UPDATE = "mcompanysetup/templates/update";
  URL_TEMPLATES_DELETE = "mcompanysetup/templates/delete";

  /*Configs*/
  URL_CONFIGS_LIST = "configs/list";
  URL_CONFIGS_WEDDING_LIST = "configs/wedding/list";
  URL_CONFIGS_SMSSENDERNAME_UPDATE = "configs/smssender/update";
  URL_CONFIGS_FREQUENT_UPDATE = "configs/frequent/update";
  URL_CONFIGS_SPENDER_UPDATE = "configs/spender/update";
  URL_CONFIGS_NOSHOW_UPDATE = "configs/noshow/update";
  URL_CONFIGS_DASHBOARD_UPDATE = "configs/dashboard/update";
  URL_CONFIGS_GLOBAL_UPDATE = "configs/globals/update";
  URL_CONFIGS_WEDDING_UPDATE = "configs/wedding/update";

  /*Integration with Micros */
  URL_INTEGRATION_CONFIG = "integrations/pos/micros/configuration";

  /*Subscriptions*/
  URL_SUBSCRIPTIONS_DETAIL = "subscriptions/detail";
  URL_SUBSCRIPTIONS_CREDITCARDS_LIST = "subscriptions/creditcards/list";
  URL_SUBSCRIPTIONS_CREDITCARDS_DELETE = "subscriptions/creditcards/delete";
  URL_SUBSCRIPTIONS_NEWCARD_URL =
    "subscriptions/paymentmethods/generateaddccurl";
  URL_SUBSCRIPTIONS_HISTORY_URL = "subscriptions/history/list";
  URL_SUBSCRIPTIONS_HISTORYDOWNLOAD_URL = "subscriptions/history/downloadpdf";

  /*SMS Management*/
  URL_SMSMANAGEMENT_GETPACKAGE = "smsmanagement/smspackage/get";
  URL_SMSMANAGEMENT_PURCHASEPACKAGE = "smsmanagement/smspackage/recharge";
  URL_SMSMANAGEMENT_GETLOGS = "smsmanagement/smslogs/list";
  URL_SMSMANAGEMENT_GETBULKLIST = "smsmanagement/bulksms/list";
  URL_SMSMANAGEMENT_GETBULKLIST_DETAIL = "smsmanagement/bulksms/list/mobilenos";
  URL_SMSMANAGEMENT_GETBULKLIST_GETDLR =
    "smsmanagement/bulksms/list/messagedlr";

  /*Public Pages*/
  /*Reservation FOrm*/
  URL_FBFORM_RETRIEVE_METADATA = "reservations/fbform/metadata";
  URL_FBFORM_RETRIEVE_RESERVATION = "reservations/fbform/getreservation";
  URL_FBFORM_ADD_RESERVATION = "reservations/fbform/addreservation";
  URL_FBFORM_CANCEL_RESERVATION = "reservations/fbform/cancel";
  URL_FBFORM_EDIT_RESERVATION = "reservations/fbform/edit";
  URL_FBFORM_RETRIEVE_SOLDTICKETS_COUNT = "reservations/fbform/soldtickets/count";
  URL_FBFORM_GUESTLIST_CHECK = "reservations/fbform/gl-check"
  URL_REQUESTS_LIST = "reservations/requests/list";
  URL_REQUESTS_SEARCH = "reservations/requests/search";
  URL_REQUESTS_SINGLE = "reservations/requests/single";
  URL_REQUESTS_MANAGE = "reservations/requests/manage";
  URL_FORGOTPASSWORD_FORGOT = "users/forgetpassword";
  URL_FORGOTPASSWORD_RESET = "users/resetpassword";

  /*Operations*/
  URL_OPERATIONS_MOVERESERVATIONS = "operations/movereservations";
  URL_OPERATIONS_IMPORTCUSTOMERS = "operations/importcustomers";
  URL_OPERATIONS_IMPORTGUESTLIST = "operations/importguestlist";
  URL_OPERATIONS_IMPORTRESERVATION = "operations/import-reservations";
  URL_OPERATIONS_SENDBULKSMS_LOOKUPS = "operations/bulksms/lookups";
  URL_OPERATIONS_PREVIEWBULKSMS = "operations/bulksms/preview";
  URL_OPERATIONS_SENDBULKSMS = "operations/bulksms/send";
  URL_OPERATIONS_LOGS = "operations/logs";
  URL_OPERATIONS_CLOSED_RESERVATIONS = "reservations/unclosed";

  /*Reports*/
  URL_REPORTS_LOGS = "reports/logs";
  URL_REPORTS_RETRIEVE = "reports/get";
  URL_OPERATION_RETRIEVE = "operation/get";
  URL_REPORTS_GENERATE = "reports/generate";

  /************************Pass************************/
  /*Events*/
  URL_EVENT_LIST = "pass/event/list";
  URL_EVENT_GETDETAIL = "pass/event/details";
  URL_EVENT_GETDASHBOARD = "pass/event/dashboard";
  URL_EVENT_CREATE = "pass/event/create";
  URL_EVENT_UPDATE = "pass/event/update";
  URL_EVENT_UPDATENOTIFICATIONS = "pass/event/update/notifications";
  URL_EVENT_UPDATEPUBLISH = "pass/event/update/publish";
  URL_EVENT_METADATA = "pass/event/metadata";
  URL_EVENT_PREVIEWMETADATA = "pass/event/preview/metadata";
  URL_EVENT_GETLAYOUTSOFBRANCH = "pass/event/branches/layouts/list";
  URL_EVENT_NEWREGISTRATION = "pass/registration/new";
  URL_EVENT_UPDATEREGISTRATION = "pass/registration/update";
  URL_EVENT_PREVIEWFEES = "pass/registration/previewfees";
  URL_EVENT_CANCELTICKETORDER = "pass/event/orders/cancelorder";
  URL_EVENT_CHANGEEVENTALIAS = "pass/event/operations/update-alias";
  URL_EVENT_RESENDCONFIRMEDTICKETS = "pass/event/operations/resend-tickets";
  URL_EVENT_SENDPPAYMENTNOTIFICATION = "pass/event/operations/send-ppayment-notification";

  /*Events Sections*/
  URL_EVENTSECTIONS_LIST = "pass/event/section/list";
  URL_EVENTSECTIONS_DETAIL = "pass/event/section/details";
  URL_EVENTSECTIONS_ADDSECTION = "pass/event/section/create";
  URL_EVENTSECTIONS_UPDATESECTION = "pass/event/section/update";
  URL_EVENTSECTIONS_DELETESECTION = "pass/event/section";

  /*Events Ticket Types*/
  URL_EVENTTICKETTYPES_LIST = "pass/event/tickets/list";
  URL_EVENTTICKETTYPES_LIST_PERIDS = "pass/event/tickets/listperids";
  URL_EVENTTICKETTYPES_DETAILS = "pass/event/tickets/details";
  URL_EVENTTICKETTYPES_ADD = "pass/event/tickets/create";
  URL_EVENTTICKETTYPES_UPDATE = "pass/event/tickets/update";
  URL_EVENTTICKETTYPES_DELETE = "pass/event/tickets"

  /*Event Codes*/
  URL_EVENTTICKETCODES_LIST = "pass/event/codes/list";
  URL_EVENTTICKETCODES_DETAILS = "pass/event/codes/details";
  URL_EVENTTICKETCODES_ADD = "pass/event/codes/create";
  URL_EVENTTICKETCODES_UPDATE = "pass/event/codes/update";
  URL_EVENTTICKETCODES_VALIDATE = "pass/event/codes/validate";
  URL_EVENTTICKETCODES_DELETE = "pass/event/codes";

  /*Event Requests*/
  URL_EVENTREQUESTS_LIST = "pass/event/requests/list";

  /*Event Orders*/
  URL_EVENTORDERS_LIST = "pass/event/orders";
  URL_EVENTORDERS_DETAILS = "pass/event/orders/details";
  URL_EVENTORDERS_DETAILSPUBLIC = "pass/event/orders/detailspublic";
  URL_EVENTORDERS_VIEWPAIDTICKET = "pass/event/orders/viewticket";
  URL_EVENTORDERS_VIEWCUSTOMPAYEMENTLINKDETAILS =
    "pass/event/orders/viewpaymentlinkdetails";
  URL_EVENTORDERS_APPROVE = "pass/event/orders/approve";
  URL_EVENTORDERS_REJECT = "pass/event/orders/reject";
  URL_EVENTORDERS_CONFIRM = "pass/event/orders/confirm";
  URL_EVENTORDERS_REJECTRESEND = "pass/event/orders/reject/resend";
  URL_EVENTORDERS_GETPAYMENTLINK = "pass/event/orders/paynow";
  URL_EVENTORDERS_VALIDATEOTP = "pass/event/orders/validateotp";
  URL_EVENTORDERS_MARKTICKETSASPAID = "pass/event/orders/markticketsaspaid";
  URL_EVENTORDERS_REFUNDTICKET = "pass/event/orders/refundticket";
  URL_EVENTORDERS_SEPERATETICKET = "pass/event/orders/seperateticket";
  URL_EVENTORDERS_CREATEPAYMENTLINK = "pass/event/orders/createpaymentlink";
  URL_EVENTORDERS_DELETEPAYMENTLINK = "pass/event/orders/deletepaymentlink";
  URL_EVENTORDERS_RESENDPAYMENTLINK = "pass/event/orders/resendpaymentlink";
  URL_EVENTORDERS_GETPAYMENTATTEMPTS = "pass/event/orders/getpaymentattempts";



  /**********************Queue***********************/
  URL_QUEUE_LIST = "queue/operations/list";
  /*********************Ordering*********************/
  /*Venues*/
  URL_VENUES_LIST = "ordering/venues/list";
  URL_VENUES_DETAILS = "ordering/venues/details";
  URL_VENUES_UPDATE = "ordering/venues/update";

  /*Branches*/
  URL_BRANCHES_LIST = "ordering/branches/list";
  URL_BRANCHES_DETAILS = "ordering/branches/details";
  URL_BRANCHES_UPDATE = "ordering/branches/update";
  URL_BRANCHES_UPDATEMAP = "ordering/branches/updatemap";

  /*Delivery Zones*/
  URL_ZONES_LIST = "ordering/zones/list";
  URL_ZONES_DETAILS = "ordering/zones/details";
  URL_ZONES_CREATE = "ordering/zones/create";
  URL_ZONES_UPDATE = "ordering/zones/update";

  URL_PRODUCTS_FULLMENU = "ordering/menu/admin/full";
  URL_PRODUCTS_MENUTYPE_CREATE = "ordering/menu/type/create";
  URL_PRODUCTS_MENUTYPE_UPDATE = "ordering/menu/type/update";
  URL_PRODUCTS_CATEGORY_CREATE = "ordering/menu/category/create";
  URL_PRODUCTS_CATEGORY_UPDATE = "ordering/menu/category/update";
  URL_PRODUCTS_CREATE = "ordering/menu/product/create";
  URL_PRODUCTS_UPDATE = "ordering/menu/product/update";
  /*****************************************************/

  /*********************Citadel*********************/
  URL_RESERVATIONS_CHANGEWEDDINGSTATUS = 'wedding/admin/reservations/newstatus';
  URL_RESERVATIONS_UPLOADWEDDINGFILES = 'wedding/admin/reservations/upload-docs';
  URL_RESERVATIONS_GETPREPRATIONDATESFORRESERVATION = 'wedding/admin/reservations/get-available-prep-dates';
  URL_RESERVATIONS_WEDDINGCANCELATIONREQUESTS = 'wedding/admin/reservations/cancellation-requests';
  /*Citadel Venues */
  URL_CITADEL_VENUES_LIST = 'wedding/venues/list';

  /*Citadel Branches */
  URL_CITADEL_BRANCHES_LIST = 'wedding/branches/list';
  URL_CITADEL_BRANCHES_PER_EVENTTYPE = "wedding/admin/tag/branches";
  URL_CITADEL_BRANCHES_ATTACHMENTS = 'wedding/branches';//wedding/branches/2/tags
  URL_CITADEL_BRANCHES_ADD = 'wedding/branches/create';
  URL_CITADEL_BRANCHES_UPDATE = 'wedding/branches/update';
  URL_CITADEL_BRANCHES_SAVEATTACHMENTS = 'mcompanysetup/tags/assign';
  URL_CITADEL_BRANCHES_DELETEIMAGE = 'wedding/branches/deleteimage';
  /*Citadel Slots */
  URL_CITADEL_SLOTS_LIST = 'wedding/admin/slots';
  URL_CITADEL_SLOTS_LIST_PER_TAGID = 'wedding/admin/slots/tag';
  /*Citadel Event Types */
  URL_CITADEL_TAGS_LIST = 'mcompanysetup/tags/listpertype';


  /*Permissions & Roles*/
  PERMISSION_SUPERADMIN = "permission_superadmin";
  PERMISSION_MCOMPANY = "permission_mcompany_";
  PERMISSION_VENUE = "permission_venue_";
  PERMISSION_BRANCH = "permission_branch_";

  /*Reservations*/
  PERMISSION_RESERVATIONSCUSTOMERSMANAGEMENT =
    "permission_reservationcustomersmanage";
  PERMISSION_RESERVATIONSCUSTOMERSVIEW = "permission_reservationcustomersview";
  PERMISSION_RESERVATIONSCUSTOMERS_CREATE =
    "permission_reservationscustomers_create";
  PERMISSION_RESERVATIONS_EDIT = "permission_reservations_edit";
  PERMISSION_RESERVATIONS_CONFIRM = "permission_reservations_confirm";
  PERMISSION_RESERVATIONS_CLOSE = "permission_reservations_close";
  PERMISSION_RESERVATIONS_CHECKIN = "permission_reservations_checkin";
  PERMISSION_RESERVATIONS_CANCEL = "permission_reservations_cancel";
  PERMISSION_RESERVATIONS_MANAGEDEPOSIT =
    "permission_reservations_managedeposit";
  PERMISSION_RESERVATIONS_ALLOCATETABLES =
    "permission_reservations_allocatetables";
  PERMISSION_RESERVATIONS_WALKINS = "permission_reservations_walkins";
  PERMISSION_RESERVATIONS_REQUESTS = "permission_reservations_handlerequests";

  /*Customers*/
  PERMISSION_ACCESSTOCUSTOMERDETAILS = "permission_customersdetails";
  PERMISSION_CUSTOMERS_CHANGECC = "permission_customers_changecc";
  PERMISSION_CUSTOMERS_BLACKLIST = "permission_customers_blacklist";
  PERMISSION_CUSTOMERS_REJECTLIST = "permission_customers_rejectlist";
  PERMISSION_CUSTOMERS_CHANGEMOBILENO = "permission_customers_changemobileno";
  PERMISSION_CUSTOMERS_CHANGEDETAILS = "permission_customers_changedetails";
  PERMISSION_CUSTOMERS_DELETE = "permission_customers_delete";

  /*System Setuop*/
  PERMISSION_TABLESETUP = "permission_tablesetup";
  PERMISSION_MCOMPANYSETUP = "permission_setup_mcompany";
  PERMISSION_CONFIGURATIONS = "permission_configs";

  /*Users*/
  PERMISSION_USERMANAGEMENT = "permission_usermanagement";
  PERMISSION_USERMANAGEMENT_ROOT = "permission_usermanagement_rootaccess";
  PERMISSION_USERMANAGEMENT_ROLES = "permission_usermanagement_roles";

  /*Finance*/
  PERMISSION_ACCESSTOCUSTOMERSFINANCE = "permission_customerfinance";
  PERMISSION_PAYMENTSHANDLE = "permission_paymenthandle";
  PERMISSION_REPORTS = "permission_reports";

  /*Events*/
  PERMISSION_EVENTS_ACCESS = "permission_events_access";
  PERMISSION_EVENTS_MANAGEDETAILS = "permission_events_manage";
  PERMISSION_EVENTS_SECTIONS = "permission_events_sections";
  PERMISSION_EVENTS_TICKETTYPES = "permission_events_tickettypes";
  PERMISSION_EVENTS_TICKETCODES = "permission_events_ticketcodes";
  PERMISSION_EVENTS_NOTIFICATIONS = "permission_events_notifications";
  PERMISSION_EVENTS_AUTOALERTS = "permission_events_autoalerts";
  PERMISSION_EVENTS_PUBLISH = "permission_events_publish";
  PERMISSION_EVENTS_OPERATIONS = "permission_events_operations";

  /*Ticket Orders*/
  PERMISSION_TICKETSCUSTOMERSMANAGEMENT = "permission_ticketscustomers_manage";
  PERMISSION_TICKETSCUSTOMERSVIEW = "permission_ticketscustomers_viewonly";
  PERMISSION_TICKETORDER_CREATE = "permission_ticketorder_create";
  PERMISSION_TICKETORDER_EDIT = "permission_ticketorder_edit";
  PERMISSION_TICKETORDER_APPROVE = "permission_ticketorder_approve";
  PERMISSION_TICKETORDER_REJECT = "permission_ticketorder_reject";
  PERMISSION_TICKETORDER_CANCEL = "permission_ticketorder_cancel";
  PERMISSION_TICKETORDER_CANCELALLUNPAIDTICKETS =
    "permission_ticketorder_cancelallunpaidtickets";
  PERMISSION_TICKETORDER_ALLOCATETABLES =
    "permission_ticketorder_allocatetables";
  PERMISSION_TICKETORDER_CHECKIN = "permission_ticketorder_checkin";
  PERMISSION_TICKETORDER_REMOVETICKET = "permission_ticketorder_removeticket";
  PERMISSION_TICKETORDER_MARKASPAIDCASH = "permission_ticketorder_markaspaid";
  PERMISSION_TICKETORDER_REFUNDPAYMENT = "permission_ticketorder_refundpayment";
  PERMISSION_TICKETORDER_MANAGEPAYMENTLINK =
    "permission_ticketorder_managepaymentlink";
  PERMISSION_TICKETORDER_SEPERATEMERGEPAYMENTGROUP =
    "permission_ticketorder_seperatemergepayments";
  PERMISSION_TICKETORDER_MANAGETAGS = "permission_ticketorder_tags";
  PERMISSION_TICKETORDER_CANSENDNOTIFICATIONSMS =
    "permission_ticketorder_sendnotificationsms";

  /* Pass Reports */
  PERMISSION_PASSREPORTS = "permission_pass_reports";
  /*End Of Permissions*/
  /*****************************************************/

  getUrl = function (url) {
    return this.WEBAPI + this[url];
  };
}
